'use strict';

var React = require("react");
var Layout = require("../components/Layout.js");
var PageWrapper = require("../components/PageWrapper.js");

function PageTemplate(Props) {
  var data = Props.data;
  var markdownRemark = data.markdownRemark;
  var html = markdownRemark.html;
  var title = markdownRemark.frontmatter.title;
  return React.createElement(Layout.make, {
              children: React.createElement(PageWrapper.make, {
                    title: title,
                    children: React.createElement("div", {
                          className: "blog-post-content",
                          dangerouslySetInnerHTML: {
                            __html: html
                          }
                        })
                  })
            });
}

var make = PageTemplate;

exports.make = make;
/* react Not a pure module */
