'use strict';

var React = require("react");
var ReactHelmet = require("react-helmet");

function PageWrapper(Props) {
  var title = Props.title;
  var children = Props.children;
  return React.createElement("div", {
              className: "article",
              itemProp: "page",
              itemScope: true
            }, React.createElement(ReactHelmet.Helmet, {
                  title: title
                }), React.createElement("div", {
                  className: "article-inner"
                }, React.createElement("header", {
                      className: "article-header"
                    }, React.createElement("h1", {
                          className: "article-title",
                          itemProp: "name"
                        }, title)), React.createElement("div", {
                      className: "article-entry",
                      itemProp: "articleBody"
                    }, children)));
}

var make = PageWrapper;

exports.make = make;
/* react Not a pure module */
